.Home {
  padding: 7vh 7vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: 'white';
}

.search {
  margin-top: 50px;
}

.homepageDescription {
  padding-left: 2vw;
  padding-top: 1vh;
}

.JumboTron {
  background: url(../assets/cover.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
