.card {
  border-top: none !important;
  text-align: left !important;
}

.toggleBtn,
.sectionToggleBtnOpen,
.sectionToggleBtnClosed {
  margin-right: 14px;
  display: inline-block;
  padding: 4px 0px !important;
  width: 34px;
  vertical-align: baseline !important;
}

/* CollapsibleHeader*/
Button:focus {
  box-shadow: none !important;
}

.sectionToggleBtnOpen {
  color: #fff !important;
}

.sectionCardHeaderOpen {
  background-color: #b94630 !important;
}

.sectionOpen,
.sectionClosed {
  display: inline-block;
  text-align: left;
  align-self: flex-start;
  font-size: 1.2rem;
  vertical-align: middle !important;
}

.sectionOpen {
  color: #fff;
}

/* CollapsibleQuestion*/
.question {
  padding-top: 6px;
}

.questionCardHeader {
  display: flex;
  justify-content: space-between !important;
}

.answer {
  text-align: left;
}
