.container {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
}

.spinner {
  width: 60px;
  height: 60px;
}
