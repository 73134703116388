body {
  width: 100%;
  overflow-x: hidden;
}

.links,
.links:hover {
  text-decoration: none;
}

.links {
  color: black;
}

.logo {
  margin-bottom: 8px;
}

.btn-outline-dark:hover > .links {
  color: white;
}

.dropdown {
  .btn-secondary {
    color: white;
    background-color: transparent;
    margin-bottom: 0.5rem;
  }

  .btn-secondary:focus,
  .btn-secondary.focus {
    background-color: transparent;
    box-shadow: none;
    border-color: #545b62;
  }
}
:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border-color: #ced4da !important;
}

.divider {
  border: 3px solid black;
  width: 201px;
}

.amenities-button {
  width: 100px;
  height: 30px;
  border: 1;
  background-color: #8cdca3;
  color: #495057;
  border-radius: 200px;
}

.review-rectangle {
  background-color: #388893;
  height: 27px;
  color: white;
}

// make sure that all pages have the contact us footer at the bottom of the screen
.root {
  min-height: calc(100vh - 94px);
}
